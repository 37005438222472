.container {
  max-width: 600px;
  margin: 30px auto;
  padding: 10px;
}

.container form > div span {
  color: red;
}

.container input,
.container textarea {
  width: 90%;
  padding: 5px 8px;
}
.required:after {
  content: " *";
  color: red;
}
.error {
  color: red
}
